import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import BarcodeReader from 'react-barcode-reader'
import toast, { Toaster } from 'react-hot-toast';

// Chakra imports
import {
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Td,
  Badge,
  Button,
  useColorModeValue,
  styled,
} from "@chakra-ui/react";

// Custom components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import TablesProjectRow from "components/Tables/TablesProjectRow";
import TablesTableRow from "components/Tables/TablesTableRow";
import { tablesProjectData, tablesTableData } from "variables/general";

import axios from 'axios';
import { COUNTER_STYLE } from "stylis";


function Tables() {

  const textColor = useColorModeValue("gray.700", "white");
  const bgStatus = useColorModeValue("gray.400", "#1a202c");
  const colorStatus = useColorModeValue("white", "gray.400");
  const [orders, setOrders] = useState([]);
  const [page, setPage] = useState(1);

  const inputStyle = {
    fontSize: '16px',
    padding: '10px',
    textAlign: 'center',
    border: '1px solid #eee'
  }


  let total = 267;

  useEffect(() => {
    getOrders();
    //paginateOrders();
    setPage(page + 1);
   }, []);


  const back = async() => {

    if(page>1){

      setPage(page - 1);
      getOrders();

    }

  }

  const next = async() => {
    
    console.log("Next: "+page);
    if(page<total){

      setPage(page + 1);
      getOrders();

    console.log("Next - : "+page);


    }


  }

  const getOrders = async () => {
    await axios.get(`https://pickingsystem.delightdistribuidores.cl/api/orders/get_list?page=`+page).then(({data})=>{
      setOrders(data.data)

      total = data.last_page;
      toast.success('Pedidos cargados.');
    })

  }


  const filterOrders = async () => {
    await axios.get(`https://pickingsystem.delightdistribuidores.cl/api/orders/get_list?page=`+page).then(({data})=>{
      setOrders(data.data)

      total = data.last_page;
      toast.success('Pedidos cargados.');
    })

  }

  const handleSearch = (e) => {

    const formData = new FormData();
    formData.append('page', page);

    formData.append('column', e.target.name);
    formData.append('value', e.target.value);

    setTimeout(() => {

       axios.post(`https://pickingsystem.delightdistribuidores.cl/api/orders_search`, formData).then(({data})=>{
        console.log(data);

        if(data){

          setOrders(data)
      
          total = data.last_page;
        


        }else{

          getOrders();
        }

      })

    }, 500);


  }
  
  const scan = async (result) => {

    if (result) {
      
      console.log(result)

      window.location.href = window.location.href + '/'.result.text

    }else{


    } 

  }

const handleScan = async (data) => {

    if (result) {
        
      console.log(result)

      window.location.href = window.location.href + '/'.result.text

    }

  }

  const getOrder = (code) =>{
    window.location.href = window.location.href + '/'+code
  }

  const handleError = (err) => {
    console.error(err)
  }


  const paginateOrders = async () => {

    const formData = new FormData();
    formData.append('limit', 10);
    formData.append('page', 1);

    formData.append('limit', 10);

    await axios.post(`https://pickingsystem.delightdistribuidores.cl/api/orders/get_list`, formData).then(({data})=>{
      console.log(data);
    })
    
  }

  const notify = () => toast.success('Here is your toast.');


  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Pedidos
          </Text>
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            <BarcodeReader
              onError={handleError}
              onScan={handleScan}
              />
            </Text>
        </CardHeader>
        <CardBody>
          <Table variant="simple" color={textColor}>
            <Thead>
              {/* <Tr my=".8rem" pl="0px" color="gray.400">
                <Th pl="0px" color="gray.400">
                  Codigo
                </Th>
                <Th color="gray.400">Estado</Th>
                <Th color="gray.400">Transportista</Th>
                <Th color="gray.400">Fecha</Th>
              </Tr> */}
              <Tr my=".8rem" pl="0px">
                <Th pl="0px" color="gray.400">
                 <input  p="3px 10px" type="text" placeholder="Codigo" name="codigo" onChange={handleSearch} style={inputStyle} />
                </Th>
                <Th color="gray.400"><input  p="3px 10px" type="text" placeholder="Estado"name="estado" onChange={handleSearch} style={inputStyle} /></Th>
                <Th color="gray.400">                 <input  p="3px 10px" type="text" placeholder="Transportista" name="transportista" onChange={handleSearch} style={inputStyle}/>
</Th>
                <Th color="gray.400">Fecha</Th>
              </Tr>
            </Thead>
            <Tbody>

                {
                  orders && orders.length > 0 && (
                    orders.map((row, key)=>(
                        <Tr key={key} onClick={(e) => getOrder(row.code)} style={{cursor: "pointer"}} bg={row.picking_status === 1 ? "#ebebf3" : "#fff"}>
                    

                              <Td>
                              <NavLink to={'/admin/pedidos/'+ row.code}>
                                <Flex direction="column">
                                  <Text fontSize="md" color={textColor} fontWeight="bold">
                                    {row.code }
                                  </Text>
                                </Flex>
                              </NavLink>

                              </Td>
                              <Td>
                              <Badge
                                bg={status === "1" ? "green.400" : bgStatus}
                                color={status === "1" ? "white" : colorStatus}
                                fontSize="16px"
                                p="3px 10px"
                                borderRadius="8px"
                              >
                                {row.order_status}
                              </Badge>
                            </Td>

                            <Td>
                              <Badge
                                bg={"green.400"}
                                color={status === "1" ? "white" : colorStatus}
                                fontSize="16px"
                                p="3px 10px"
                                borderRadius="8px"
                              >
                                {row.name}
                              </Badge>
                            </Td>

                            <Td>
                                <Flex direction="column">
                                  <Text fontSize="md" color={textColor} fontWeight="bold">
                                  {row.date_add}
                                  </Text>
                                </Flex>
                              </Td>
                        </Tr>

                      ))
                  )
              }
            </Tbody>
          </Table>
        </CardBody>
      </Card>
      <br/>
        <Flex>

        <Button p="20px" onClick={(e) => back()}>
          <text>
              Atrás
          </text>
        </Button>

        <Button p="20px"  onClick={(e) => next()}>
          <text>
              Siguiente
          </text>
        </Button>

        </Flex>
    </Flex>
  );
}

export default Tables;
