import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import BarcodeScannerComponent from "react-qr-barcode-scanner";
import BarcodeReader from 'react-barcode-reader'
import toast, { Toaster } from 'react-hot-toast';

// Chakra imports
import {
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Input,
  Td,
  Badge,
  Button,
  useColorModeValue,
  SimpleGrid,
  Spacer,
} from "@chakra-ui/react";

import { NavLink } from "react-router-dom";


// Custom components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import axios from 'axios';
import { COUNTER_STYLE } from "stylis";


function OrderDetails() {

  const textColor = useColorModeValue("gray.700", "white");
  const bgStatus = useColorModeValue("gray.400", "#1a202c");
  const colorStatus = useColorModeValue("white", "gray.400");
  const [data, setData] = React.useState("Not Found");
  const [picking, setPicking] = useState([]);
  const [order, setOrder] = useState();
  const [products, setProducts] = useState([]);


  const  { reference } = useParams();

  useEffect(() => {
    getOrderDetails();
    updatePickingTable();
   }, []);


  const getOrderDetails = async () => {
    await axios.get(`https://pickingsystem.delightdistribuidores.cl/api/orders/`+reference).then(({data})=>{

       setOrder(data.order);
       setProducts(data.products);

    })

  }
  
  
  const updatePickingTable = async () => {
    
    await axios.get(`https://pickingsystem.delightdistribuidores.cl/api/get_products_picked/`+reference).then(({data})=>{

       setPicking(data);

    })

  }

  const handleScan = async (data) => {

    if (data) {
      
      console.log(data);
      setData(data);

      save_picking(data);
  

    }else{

      setData("Sin datos");
      toast.error('No existe el codigo de barra en la base de datos');

    } 
  }

  const handleError = (err) => {
    console.error(err)
  }

  const removePick = async (data) => {

    console.log("Picking ID: "+data);

    const formData = new FormData();
    formData.append('order_id', reference);
    formData.append('picking_id', data);

    await axios.post(`https://pickingsystem.delightdistribuidores.cl/api/picking/unpick_product`, formData).then(({data})=>{

      console.log(data);

      if(data) {

        updatePickingTable();
        getOrderDetails();

        if(data == "Error"){
          toast.error('Ha ocurrido un error al eliminar el producto');

        }else{

          toast.success('Producto eliminado');

        }

      }else{

        toast.error('Ha ocurrido un error al eliminar el producto');

      }



   })

  }

  const scan = async (result) => {

    if (result) {
      
      console.log(result);
      setData(result.text);

      save_picking(result.text);

    }else{

      setData("Sin datos");

    } 

  }

  const save_picking = async (code) => {

      const formData = new FormData();
      formData.append('order_id', reference);
      formData.append('product_code', code);
      formData.append('quantity', 1);

      await axios.post(`https://pickingsystem.delightdistribuidores.cl/api/picking/pick_product`, formData).then(({data})=>{

       console.log(data);

       if(data) {

        updatePickingTable();
        getOrderDetails();

       toast.success('Producto pickeado');

       }else{

       toast.error('Ha ocurrido un error al pickear el producto');

       }



      })
      


  }


  return (
    <Flex direction="column" pt={{ base: "60px", md: "50px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
        <CardHeader p="6px 0px 22px 0px">
        <Flex flexdirection="column" pt={{ base: "120px", md: "75px" }}>
          <SimpleGrid columns={{ sm: 1, md: 2, xl: 2 }} spacing="24px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Pedido {reference}
            </Text>
            <Text fontSize="xl" color={textColor} fontWeight="bold">
            <BarcodeReader
              onError={handleError}
              onScan={handleScan}
              />
            </Text>
            </SimpleGrid>
          </Flex>
        </CardHeader>
        <CardBody>
          <Table variant="simple" color={textColor}>
            <Thead>
              <Tr my=".8rem" pl="0px" color="gray.400">
                <Th pl="0px" color="gray.400">
                  Codigo
                </Th>
                <Th color="gray.400">Nombre</Th>
                <Th color="gray.400">Cantidad</Th>
                <Th color="gray.400">Ubicación</Th>
              </Tr>
            </Thead>
            <Tbody>
                {
                  products && products.length > 0 && (
                    products.map((row, key)=>(

                        <Tr key={key} bg={!row.quantity? "green.200": "white"} >
                              <Td>
                                <Flex direction="column">
                                  <Text fontSize="md" color={textColor} fontWeight="bold">
                                    {row.code}
                                  </Text>
                                </Flex>
                              </Td>

                              <Td>
                                <Flex direction="column">
                                  <Text fontSize="md" color={textColor} fontWeight="bold">
                                    {row.title}
                                  </Text>
                                </Flex>
                              </Td>
                              <Td>
                              <Badge
                                bg={status === "1" ? "green.400" : bgStatus}
                                color={status === "1" ? "white" : colorStatus}
                                fontSize="16px"
                                p="3px 10px"
                                borderRadius="8px"
                              >
                                {row.quantity}
                              </Badge>
                            </Td>

                            {/* <Td>
                                <Flex direction="column">
                                  <Text fontSize="md" color={textColor} fontWeight="bold">
                                  {row.date_add}
                                  </Text>
                                </Flex>
                              </Td> */}
                              <Td>
                                
                                  <Text fontSize="md" color={textColor} fontWeight="bold">
                                  {row.location?row.location:"Sin definir"}
                                  </Text>
                              </Td>

                              {/* <Td> */}
                                  {/* {row.quantity>0 && (

                                  <Button onClick={(e) => handleScan(row.mpn)}>
                                  <Text  fontSize="md" fontWeight="bold" cursor="pointer">Pickear</Text>
                                  </Button>

                                  )} */}
                              {/* </Td> */}
                        </Tr>
                      ))
                  )
              }
            </Tbody>
          </Table>
        </CardBody>
      </Card>
      <Spacer></Spacer>
      { picking && picking.length > 0 &&
      <Card bg="gray.100" mt="30px" overflowX={{ sm: "scroll", xl: "hidden" }}>
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Picking
          </Text>
        </CardHeader>
        <CardBody>
          <Table variant="simple" color={textColor}>
            <Thead>
              <Tr my=".8rem" pl="0px" color="gray.400">
                <Th pl="0px" color="gray.400">
                  Codigo
                </Th>
                <Th color="gray.400">Cantidad</Th>
                <Th color="gray.400">Fecha</Th>
                <Th color="gray.400">Acciones</Th>

              </Tr>
            </Thead>
            <Tbody>

                
            {
                  picking && picking.length > 0 && (
                    picking.map((row, key)=>(

                        <Tr key={key}>
                              <Td>
                                <Flex direction="column">
                                  <Text fontSize="md" color={textColor} fontWeight="bold">
                                    {row.product_code}
                                  </Text>
                                </Flex>
                              </Td>

                              <Td>
                              <Badge
                                bg={status === "1" ? "green.400" : bgStatus}
                                color={status === "1" ? "white" : colorStatus}
                                fontSize="16px"
                                p="3px 10px"
                                borderRadius="8px"
                              >
                                {row.quantity}
                              </Badge>
                            </Td>


                            <Td>
                                <Flex direction="column">
                                  <Text fontSize="md" color={textColor} fontWeight="bold">
                                  {row.updated_at}
                                  </Text>
                                </Flex>
                              </Td>
                              
                              <Td>

                                  <Button onClick={(e) => removePick(row.id)} colorScheme="teal">
                                  <Text  fontSize="md" fontWeight="bold" cursor="pointer">Eliminar pick</Text>
                                  </Button>

                              </Td>
                        </Tr>
                      ))
                  )
              }
               
            </Tbody>
          </Table>
        </CardBody>
      </Card>
    }
    </Flex>
  );
}

export default OrderDetails;
